$(window).on('load', function() {
  //var LIGHTBOX_HTML = '/templates/main/lightbox/nye2018/index.html';

  /*function showLightbox() {
    var lightbox_seen = document.cookie.replace(
      /(?:(?:^|.*;\s*)lightbox_seen\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );

    Uncomment while developing
    lightbox_seen = false;

    var newYearsDate = new Date('December 31, 2018 22:00:00 -0500');
    var today = new Date();

    console.log("Now: " + today, "NYE Lightbox removal: " + newYearsDate + " (10:00 PM EST)");
    console.log(today < newYearsDate);
    if (window.innerWidth > 700 && !lightbox_seen && today < newYearsDate) {
      $(".ifm-iframe-holder").append($("<iframe/>", {
          src: LIGHTBOX_HTML,
          frameborder: "0",
          scrolling: "no",
          width: "100%",
          height: "100%"
        }));

      $(".ifm-overlay, .ifm-lightbox").addClass("ifm-lightbox-is-active");

      $(".ifm-overlay, .ifm-close").on("click", function(e) {
        e.preventDefault();
        $(".ifm-overlay, .ifm-lightbox").removeClass("ifm-lightbox-is-active");
      });

      // once per session
      document.cookie = "lightbox_seen=true";
    }
  }*/

  if (on_property === false) {
    // console.log('on_property', on_property);
    // showLightbox();
  }

  // other lightboxes
  // var lbPath = '/templates/main/lightbox/forever/index.html';
  var lbPathnye = '/templates/main/lightbox/nye2016/index.html';
  var lbPathBFCM = '/templates/main/lightbox/black-friday/index.html';
  var lbPathSpring = '/templates/main/lightbox/spring-sale/index.html';
  //var lbPathWellness = '/templates/main/lightbox/wellness/index.html';
  var lbPath = '/templates/main/lightbox/summer-in-sept-2018/index.html';

  // if on a MotionPoint language page
  if (MP) {
    if (MP.UrlLang !== 'en') return false;
  }

  function showSummerSaleLightbox() {
    var summerSaleLightboxSeen = document.cookie.replace(
      /(?:(?:^|.*;\s*)summersale_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    summerSaleLightboxSeen = summerSaleLightboxSeen === 'true';

    // Uncomment while developing
    // summerSaleLightboxSeen = false;

    if (window.innerWidth > 700 && !summerSaleLightboxSeen) {
      $('.summersale-overlay, .summersale-lightbox').addClass(
        'summersale-lightbox-is-active'
      );

      $('.summersale-overlay, .summersale-close').on('click', function(e) {
        e.preventDefault();

        $('.summersale-overlay, .summersale-lightbox').removeClass(
          'summersale-lightbox-is-active'
        );
      });

      document.cookie = 'summersale_lightbox=true';
    }
  }

  function showMiamiSpiceLightbox() {
    var miamiSpiceLightboxSeen = document.cookie.replace(
      /(?:(?:^|.*;\s*)miamispice_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    miamiSpiceLightboxSeen = miamiSpiceLightboxSeen === 'true';

    // Uncomment while developing
    //miamiSpiceLightboxSeen = false;

    if (window.innerWidth > 700 && !miamiSpiceLightboxSeen) {
      $('.miamispice-overlay, .miamispice-lightbox').addClass(
        'miamispice-lightbox-is-active'
      );

      $('.miamispice-overlay, .miamispice-close').on('click', function(e) {
        e.preventDefault();

        $('.miamispice-overlay, .miamispice-lightbox').removeClass(
          'miamispice-lightbox-is-active'
        );
      });

      document.cookie = 'miamispice_lightbox=true';
    }
  }

  function showBFCMLightbox() {
    var BFCMLightboxSeen = document.cookie.replace(
      /(?:(?:^|.*;\s*)bfcm_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    BFCMLightboxSeen = BFCMLightboxSeen === 'true';

    // Uncomment while developing
    //BFCMLightboxSeen = false;

    if (window.innerWidth >= 920 && !BFCMLightboxSeen) {
      $('.ifm-iframe-holder').append(
        $('<iframe/>', {
          src: lbPathBFCM,
          frameborder: '0',
          scrolling: 'no',
          width: '100%',
          height: '100%'
        })
      );

      $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

      $('.ifm-overlay, .ifm-close').on('click', function(e) {
        e.preventDefault();

        $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
      });

      // once per session
      document.cookie = 'bfcm_lightbox=true';
    }
  }

  function showSpringSale() {
    var showSpringSaleSeen = document.cookie.replace(
      /(?:(?:^|.*;\s*)springsale_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    showSpringSaleSeen = showSpringSaleSeen === 'true';

    // Uncomment while developing
    //showSpringSaleSeen = false;

    if (window.innerWidth >= 920 && !showSpringSaleSeen) {
      $('.ifm-iframe-holder').append(
        $('<iframe/>', {
          src: lbPathSpring,
          frameborder: '0',
          scrolling: 'no',
          width: '100%',
          height: '100%'
        })
      );

      $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

      $('.ifm-overlay, .ifm-close').on('click', function(e) {
        e.preventDefault();

        $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
      });

      // once per session
      document.cookie = 'springsale_lightbox=true';
    }
  }

  var lbPathSeptShortSale = '/templates/main/lightbox/72-hour/index.html';
  function showSeptShortSaleLightbox() {
    var SeptShortSaleLightboxSeen = document.cookie.replace(
      /(?:(?:^|.*;\s*)SeptShortSale_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    SeptShortSaleLightboxSeen = SeptShortSaleLightboxSeen === 'true';

    // Uncomment while developing
    // SeptShortSaleLightboxSeen = false; // TODO

    if (window.innerWidth >= 920 && !SeptShortSaleLightboxSeen) {
      $('.ifm-iframe-holder').append(
        $('<iframe/>', {
          src: lbPathSeptShortSale,
          frameborder: '0',
          scrolling: 'no',
          width: '100%',
          height: '100%'
        })
      );

      $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

      $('.ifm-overlay, .ifm-close').on('click', function(e) {
        e.preventDefault();

        // GTM
        window.dataLayer = window.dataLayer || []; // GOOGLE TAG MANAGER (GTM)
        window.dataLayer.push({
          event: 'lightboxEvent',
          eventCategory: 'lightbox',
          eventAction: 'click - 72hrFallSale-lightbox',
          eventLabel: 'closed'
        });

        $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
      });

      // once per session
      document.cookie = 'SeptShortSale_lightbox=true';
    }
  }

  /* Fall For Bleau */
  var lbPathFall = '/templates/main/lightbox/fall-for-bleau/index.html';
  function showFallForBleau() {
    var endlessSummerSeen = document.cookie.replace(
      /(?:(?:^|.*;\s*)fall_for_bleau\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    endlessSummerSeen = endlessSummerSeen === 'true';
    // Uncomment while developing
    //     endlessSummerSeen = false;

    if (window.innerWidth >= 920 && !endlessSummerSeen) {
      $('.ifm-iframe-holder').append(
        $('<iframe/>', {
          src: 'https://fontainebleau.com' + lbPathFall,
          frameborder: '0',
          scrolling: 'no',
          width: '100%',
          height: '100%'
        })
      );

      $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

      $('.ifm-overlay, .ifm-close').on('click', function(e) {
        e.preventDefault();

        $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
      });

      // once per session
      document.cookie = 'fall_for_bleau=true';
    }
  }
  /* Fall For Bleau */

    /* Start of July Holiday 2019 */

    var lbPathJulyHoliday2019 =
        '/templates/main/lightbox/july-holiday-2019/index.html';
    function showJulyHoliday2019Lightbox() {
        var JulyHoliday2019LightboxSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)JulyHoliday2019_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        var JulyHoliday2019LightboxMobSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)JulyHoliday2019_lightbox_mobile\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        JulyHoliday2019LightboxSeen = JulyHoliday2019LightboxSeen === 'true';
        JulyHoliday2019LightboxMobSeen = JulyHoliday2019LightboxMobSeen === 'true';
        console.log("JulyHoliday2019LightboxSeen" , JulyHoliday2019LightboxMobSeen);
        // Uncomment while developing
         //JulyHoliday2019LightboxSeen = false;
        if(!JulyHoliday2019LightboxMobSeen){
            $('.home-mobile-july-lightbox').addClass('show-for-small-only');
            $('.home-mobile-july-lightbox').attr("style", "z-index:99999; position:relative; height:208px;");
            document.cookie = 'JulyHoliday2019_lightbox_mobile=true';
        }

        if (window.innerWidth >= 920 && !JulyHoliday2019LightboxSeen) {
            $('.ifm-iframe-holder').append(
                $('<iframe/>', {
                    src: lbPathJulyHoliday2019,
                    frameborder: '0',
                    scrolling: 'no',
                    width: '100%',
                    height: '100%'
                })
            );

            $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

            $('.ifm-overlay, .ifm-close').on('click', function(e) {
                e.preventDefault();

                // GTM
                window.dataLayer = window.dataLayer || []; // GOOGLE TAG MANAGER (GTM)
                window.dataLayer.push({
                    event: 'lightboxEvent',
                    eventCategory: 'lightbox',
                    eventAction: 'click - July Holiday',
                    eventLabel: 'closed'
                });

                $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
            });

            // once per session
            document.cookie = 'JulyHoliday2019_lightbox=true';
        }
    }
    
    var jh2019EndDate = new Date("July 31, 2019 23:59:59");
    var today = new Date();
    today = new Date(today.getFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        today.getUTCHours(),
        today.getMinutes(),
        today.getSeconds());

    if(today <= jh2019EndDate &&  $("body").hasClass('home')){
            showJulyHoliday2019Lightbox();
    }

    /*End of July Holiday 2019*/

    /* Start of August 2019 */
    var lbPathAugust2019 = '/templates/main/lightbox/august-2019/index.html';
    function showAugust2019Lightbox() {
        var August2019LightboxSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)JulyHoliday2019_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        var August2019LightboxMobSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)JulyHoliday2019_lightbox_mobile\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        August2019LightboxSeen = August2019LightboxSeen === 'true';
        August2019LightboxMobSeen = August2019LightboxMobSeen === 'true';
        console.log("August2019LightboxSeen" , August2019LightboxMobSeen);
        // Uncomment while developing
        August2019LightboxSeen = false;
        if(!August2019LightboxMobSeen){
            $('.home-mobile-august-lightbox').addClass('show-for-small-only');
            $('.home-mobile-august-lightbox').attr("style", "z-index:99999; position:relative; height:208px;");
            document.cookie = 'August2019_lightbox_mobile=true';
        }

        if (window.innerWidth >= 920 && !August2019LightboxSeen) {
            $('.ifm-iframe-holder').append(
                $('<iframe/>', {
                    src: lbPathAugust2019,
                    frameborder: '0',
                    scrolling: 'no',
                    width: '100%',
                    height: '100%'
                })
            );

            $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

            $('.ifm-overlay, .ifm-close').on('click', function(e) {
                e.preventDefault();

                // GTM
                window.dataLayer = window.dataLayer || []; // GOOGLE TAG MANAGER (GTM)
                window.dataLayer.push({
                    event: 'lightboxEvent',
                    eventCategory: 'lightbox',
                    eventAction: 'click - August 2019',
                    eventLabel: 'closed'
                });

                $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
            });

            // once per session
            document.cookie = 'August2019_lightbox=true';
        }
    }

    var aug2019StartDate = new Date("August 1, 2019 00:00:00");
    var aug2019EndDate = new Date("August 31, 2019 23:59:59");
    var today = new Date();
    today = new Date(today.getFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        today.getUTCHours(),
        today.getMinutes(),
        today.getSeconds());

    if(today >= aug2019StartDate && today <= aug2019EndDate &&  $("body").hasClass('home')){
        showAugust2019Lightbox();
    }
    /*End of August 2019*/

    /* Start of Cyber Sale November 2019 */
    var lbPathCyberSaleNov2019 = '/templates/main/lightbox/cyber-sale-2019/index.html';
    function showCyberSaleNov2019Lightbox() {
        var CyberSaleNov2019LightboxSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)CyberSaleNov2019_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        var CyberSaleNov2019LightboxMobSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)CyberSaleNov2019_lightbox_mobile\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        CyberSaleNov2019LightboxSeen = CyberSaleNov2019LightboxSeen === 'true';
        CyberSaleNov2019LightboxMobSeen = CyberSaleNov2019LightboxMobSeen === 'true';
        console.log("CyberSaleNov2019LightboxSeen" , CyberSaleNov2019LightboxMobSeen);
        // Uncomment while developing
        //CyberSaleNov2019LightboxSeen = false;
        //CyberSaleNov2019LightboxMobSeen = false;

        if(!CyberSaleNov2019LightboxMobSeen){
            $('.home-mobile-cybersale-lightbox').addClass('show-for-small-only');
            $('.home-mobile-cybersale-lightbox').attr("style", "z-index:99999; position:relative; height:208px;");
            document.cookie = 'CyberSaleNov2019_lightbox_mobile=true';
        }

        if (window.innerWidth >= 920 && !CyberSaleNov2019LightboxSeen) {
            $('.ifm-iframe-holder').append(
                $('<iframe/>', {
                    src: lbPathCyberSaleNov2019,
                    frameborder: '0',
                    scrolling: 'no',
                    width: '100%',
                    height: '100%'
                })
            );

            $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

            $('.ifm-overlay, .ifm-close').on('click', function(e) {
                e.preventDefault();

                // GTM
                window.dataLayer = window.dataLayer || []; // GOOGLE TAG MANAGER (GTM)
                window.dataLayer.push({
                    event: 'lightboxEvent',
                    eventCategory: 'lightbox',
                    eventAction: 'click - Cyber Sale 2019',
                    eventLabel: 'closed'
                });

                $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
            });

            // once per session
            document.cookie = 'CyberSaleNov2019_lightbox=true';
        }
    }

    var cyberSale2019StartDate = new Date("November 25, 2019 00:00:00");
    var cyberSale2019EndDate = new Date("December 2, 2019 23:59:59");
    var today = new Date();
    today = new Date(today.getFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        today.getUTCHours(),
        today.getMinutes(),
        today.getSeconds());

    if(today >= cyberSale2019StartDate && today <= cyberSale2019EndDate &&  $("body").hasClass('home')){
        showCyberSaleNov2019Lightbox();
    }
    /*End of Cyber Sale November 2019 */



    /* Start of NYE 2019 */
    var lbPathNYE2019 = '/templates/main/lightbox/nye2019/index.html';
    function showNYE2019Lightbox() {
        var NYE2019LightboxSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)NYE2019_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        var NYE2019LightboxMobSeen = document.cookie.replace(
            /(?:(?:^|.*;\s*)NYE2019_lightbox_mobile\s*\=\s*([^;]*).*$)|^.*$/,
            '$1'
        );
        NYE2019LightboxSeen = NYE2019LightboxSeen === 'true';
        NYE2019LightboxMobSeen = NYE2019LightboxMobSeen === 'true';
        console.log("NYE2019LightboxSeen", NYE2019LightboxMobSeen);
        // Uncomment while developing
        //NYE2019LightboxSeen = false;
        //NYE2019LightboxMobSeen = false;

        // if(!NYE2019LightboxMobSeen){
        //     $('.home-mobile-cybersale-lightbox').addClass('show-for-small-only');
        //     $('.home-mobile-cybersale-lightbox').attr("style", "z-index:99999; position:relative; height:208px;");
        //     document.cookie = 'NYE2019_lightbox_mobile=true';
        // }

        if (!NYE2019LightboxSeen) {
            $('.ifm-iframe-holder').append(
                $('<iframe/>', {
                    src: lbPathNYE2019,
                    frameborder: '0',
                    scrolling: 'no',
                    width: '100%',
                    height: '100%'
                })
            );

            $('.ifm-overlay, .ifm-lightbox').addClass('ifm-lightbox-is-active');

            $('.ifm-overlay, .ifm-close').on('click', function(e) {
                e.preventDefault();

                // GTM
                window.dataLayer = window.dataLayer || []; // GOOGLE TAG MANAGER (GTM)
                window.dataLayer.push({
                    event: 'lightboxEvent',
                    eventCategory: 'lightbox',
                    eventAction: 'click - NYE 2019',
                    eventLabel: 'closed'
                });

                $('.ifm-overlay, .ifm-lightbox').removeClass('ifm-lightbox-is-active');
            });

            // once per session
            document.cookie = 'NYE2019_lightbox=true';
        }
    }

    var NYE2019StartDate = new Date("December 3, 2019 00:00:00");
    var NYE2019EndDate = new Date("December 31, 2019 23:59:59");
    var today = new Date();
    today = new Date(today.getFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        today.getUTCHours(),
        today.getMinutes(),
        today.getSeconds());

    if(today >= NYE2019StartDate && today <= NYE2019EndDate &&  $("body").hasClass('home')){
      showNYE2019Lightbox();
    }
    /*End of NYE 2019 */


    /* mobile lightbox */
    $('.mobile-lightbox-close').on('click', function(e) {
        e.preventDefault();
        console.log("on close click");
        $('.home-mobile-lightbox').attr("style", "display:none !important;");
        $('.home-mobile-lightbox').removeClass('show-for-small-only');
    });

});

$(document).ready(function() {
  //showMobileLightbox();
  // make some waves.
  // forked via https://codepen.io/HeidiChang/pen/gPwPZX
  if (navigator.userAgent.match(/AppleWebKit/i)) {
    var ocean = document.getElementById('ocean'),
      waveWidth = 10,
      waveCount = Math.floor(window.innerWidth / waveWidth),
      docFrag = document.createDocumentFragment();
    if (ocean != null) {
      for (var i = 0; i < waveCount; i++) {
        var wave = document.createElement('div');
        wave.className += ' wave';
        docFrag.appendChild(wave);
        wave.style.left = i * waveWidth + 'px';
        wave.style.animationDelay = i / 100 + 's';

        var wave_middle = document.createElement('div');
        wave_middle.className += ' wave_middle';
        docFrag.appendChild(wave_middle);
        wave_middle.style.left = i * waveWidth + 'px';
        wave_middle.style.animationDelay = i / 91 + 's';

        var wave_bottom = document.createElement('div');
        wave_bottom.className += ' wave_bottom';
        docFrag.appendChild(wave_bottom);
        wave_bottom.style.left = i * waveWidth + 'px';
        wave_bottom.style.animationDelay = i / 97 + 's';

        var wave_light = document.createElement('div');
        wave_light.className += ' wave_light';
        docFrag.appendChild(wave_light);
        wave_light.style.left = i * waveWidth + 'px';
        wave_light.style.animationDelay = 0 + 's';
      }

      ocean.appendChild(docFrag);
    }
  }
});

function showMobileLightbox() {
  var mobileLightBoxSeen = document.cookie.replace(
    /(?:(?:^|.*;\s*)mobile_lightbox\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  mobileLightBoxSeen = mobileLightBoxSeen === 'true';

  // Uncomment while developing
  //mobileLightBoxSeen = false;

  if (window.innerWidth <= 425 && !mobileLightBoxSeen) {
    $('body').addClass('control-overflow');

    $('.mobile-lightbox-overlay, .mobile-lightbox').addClass(
      'mobile-lightbox-is-active'
    );

    setTimeout(function() {
      $('.mobile-lightbox-is-active.mobile-lightbox').css(
        'transform',
        'translateX(0)'
      );
    }, 600);

    $('.mobile-lightbox-overlay, .mobile-lightbox-close').on('click', function(
      e
    ) {
      e.preventDefault();
      $('body').removeClass('control-overflow');
      $('.mobile-lightbox-overlay, .mobile-lightbox').removeClass(
        'mobile-lightbox-is-active'
      );
    });

    $('.mobile-lightbox-btn').on('click', function() {
      dataLayer.push({ event: 'mobileLightboxClicked' });
    });

    document.cookie = 'mobile_lightbox=true';
  }
}